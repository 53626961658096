<template>
  <div class="main">
    <h2>{{ $t('msg.exports.title') }}</h2>
    <p class="my-4">{{ $t('msg.exports.subtitle') }}</p>
    <div class="card p-3">
      <div class="row card-body">
        <div class="col-xl-5 col-lg-12 mb-2">
          <h5>{{ $t('form') }}</h5>
        </div>
        <div class="col-xl-7 col-lg-12">
          <a-form-model
            ref="form"
            :model="form"
            :rules="rules">
            <j-field
              prop="type">
              <a-radio-group v-model="form.type">
                <a-radio :style="style" value="BILLING_SHIPMENTS">
                  Facturación de envíos
                </a-radio>
                <a-radio :style="style" value="BILLING_COLLECTS">
                  Facturación de colectas
                </a-radio>
                <a-radio :style="style" value="BILLING_COURIERS">
                  Facturación de transportistas
                </a-radio>
                <a-radio :style="style" value="REPORT_DISPATCHED">
                  Seguimiento de envíos en tránsito
                </a-radio>
              </a-radio-group>
            </j-field>
            <j-field
              prop="dateMin">
              <a-date-picker
                v-model="form.dateMin"
                size="large"
                :show-time="{ defaultValue: time } "/>
            </j-field>
            <j-field
              prop="dateMax">
              <a-date-picker
                v-model="form.dateMax"
                size="large"
                :show-time="{ defaultValue: time } "/>
            </j-field>
          </a-form-model>
          <!-- Email -->
          <div class="font-size-16 mt-2">
            {{ $t('msg.exports.message') }}
            <b>{{ email }}</b>.
          </div>
          <!-- Submit -->
          <div class="font-size-18 mt-4">
            <a-button
              @click="onSubmit"
              type="primary"
              size="large">
              {{ $t('msg.exports.submit') }}
            </a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import lms from '@/api/lms'
import v from '@/utils/validators'
import JField from '@/views/shared/forms/field'
export default {
  components: {
    JField,
  },
  data: function () {
    return {
      v,
      form: {
        type: undefined,
        dateMin: undefined,
        dateMax: undefined,
      },
      style: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      rules: {
        type: [v.required],
        dateMin: [v.required],
        dateMax: [v.required],
      },
    }
  },
  computed: {
    time() {
      return moment('00:00:00', 'HH:mm:ss')
    },
    email() {
      return this.$store.getters['session/USER'].email
    },
  },
  methods: {
    onSubmit() {
      Promise.all([
        new Promise((resolve, reject) => this.$refs.form.validate(resolve)),
      ]).then(results => {
        if (results.every(Boolean)) {
          const body = {
            type: this.form.type,
            email: this.email,
            filter: {
              date_range_min: this.form.dateMin,
              date_range_max: this.form.dateMax,
            },
          }
          lms.exports.start(body)
            .then(() => this.$router.push('/shipments/exported'))
        }
      })
    },
  },
}
</script>
